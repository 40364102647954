<template>
  <div>
    <tabBar :cardIndex="cardIndex" :activeIndexs="0" />
    <div style="padding-top: 120px">
      <div class="scheme">
        <div class="scheme_center">
          <div class="titles">来自专业牙齿美白领导者的美白牙膏</div>
          <div class="centersimg">
            <div class="imgs_left">
              <img
                class="imgs_leftads"
                src="../assets/img/toothpaste-product-original.jpg"
                alt=""
              />
              <div class="imgadsbtns">
                <span>美白牙膏</span>
                <!-- <div class="carts" @click="lsitads(shopids[0])">立即购买</div> -->
              </div>
              <div class="skus">
                <div>清凉的薄荷味</div>
                <div>4.7oz/1.0oz（≈133g/28g）</div>
              </div>
              <ul class="uls">
                <li class="li">美白配方</li>
                <li class="li">
                  去除牙齿表面污渍，使牙齿在一个月内提亮两个色阶
                </li>
                <li class="li">含有氟化钠，有助于预防蛀牙和强化牙釉质</li>
                <li class="li">氟化钠供有效吸收</li>
                <li class="li">适宜每日使用</li>
                <li class="li">清凉的薄荷味让口气清新持久</li>
                <li class="li">纯素食-不使用动物制品</li>
                <li class="li">Kosher认证-犹太食品认证</li>
              </ul>
            </div>
            <div class="imgs_left">
              <img
                class="imgs_leftads"
                src="../assets/img/toothpaste-product-sensitivity.jpg"
                alt=""
              />
              <div class="imgadsbtns bgs">
                <span>抗敏牙膏</span>
                <!-- <div class="carts bgsborder" @click="lsitads(shopids[1])">
                  立即购买
                </div> -->
              </div>
              <div class="skus">
                <div>清凉的薄荷味</div>
                <div>4.7oz/1.0oz（≈133g/28g）</div>
              </div>
              <ul class="uls">
								<li class="li">美白配方</li>
                <li class="li">添加抗敏感配方</li>
                <li class="li">
                  去除牙齿表面污渍，使牙齿在一个月内提亮两个色阶
                </li>
                <li class="li">含有氟化钠，有助于预防蛀牙和强化牙釉质</li>
                <li class="li">氟化钠供有效吸收</li>
                <li class="li">适宜每日使用</li>
                <li class="li">清凉的薄荷味让口气清新持久</li>
                <li class="li">纯素食-不使用动物制品</li>
                <li class="li">Kosher认证-犹太食品认证</li>
              </ul>
            </div>
          </div>
        </div>
        <triangle :back="'#00B2A9'"></triangle>
      </div>
    </div>
    <div class="schemecens">
      <div class="schemecens_center">
        <img
          class="pngstg"
          src="../assets/img/toothpaste-productp.png"
          alt=""
        />
        <img class="topimg" src="../assets/img/logings6.png" alt="" />
        <div class="centertitle">如何在30天内美白牙齿</div>
        <div class="centertext">
          奥普斯美白牙膏是由一位牙医研发的，所以它与其他牙膏大不相同。它独特的三氧化硅混合物能有效去除牙齿表面的污渍，在短短30天内能使牙齿提升两个色阶。此外，它足够温和，完全可以放心每天使用。搭配我们专业的牙齿美白套装进行美白维护，一口亮白的牙齿，让你放心大胆地微笑。
        </div>
      </div>
    </div>
    <div class="schennums">
      <div class="schennums_center">
        <div class="svjleft">
          <div class="title">4周内提亮牙齿2个色阶，给你自信灿烂的笑容</div>
          <div class="texts">
            在纽约大学的一项研究中，一组健康的成年人用奥普斯美白牙膏刷上颌牙齿，用不含美白功效的普通牙膏刷下颌牙齿。按照指示刷牙一个月后，结果非常明显。对比表明，用奥普斯美白牙膏刷牙的上颌牙平均提升了两个或两个以上的色阶!
          </div>
        </div>
        <img class="imgtext" src="../assets/img/toothpasteModel.png" alt="" />
      </div>
    </div>
    <div class="nuvone">
      <div class="nuvone_center">
        <img
          class="toothpasteimg"
          src="../assets/img/tooth.png"
          alt=""
        />
        <div class="toothpasteright">
          <div class="title">安全放心 每天使用</div>
          <div class="texts">
            研磨性较强的牙膏可能会削弱牙釉质，导致牙龈萎缩，如果每天使用，会使牙齿更容易感到敏感和过度研磨。与市面上其他美白牙膏相比，奥普斯美白牙膏的研磨性更小，非常安全，因此可以放心每天使用。你不必在便利性或质量上做选择，奥普斯美白牙膏都可以满足你。这是你唯一需要的美白牙膏。
          </div>
        </div>
      </div>
    </div>
    <div class="townie">
      <div class="townie_center">
        <div class="townie_left">
          <div class="titles">获奖的配方</div>
          <div class="texts">
            奥普斯美白牙膏会通过强化牙釉质和帮助预防蛀牙来维护你的牙齿健康。此外，它含有木糖醇，可以降低蛀牙的风险。我们的抗敏配方具有与原配方相同的美白牙齿功效，同时，也有助于缓解牙齿敏感引起的酸痛。奥普斯美白牙膏效果出众，所以更是多次赢得了由同行推荐的最全面的牙科产品大奖- Townie Choice的“最佳美白牙膏”的称号!
          </div>
        </div>
        <div class="townie_right">
          <img
            class="townieimg"
            src="../assets/img/toothpaste-townie.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="explain">
      <div class="explain_center">
        <div class="shop_name">
          <div class="shop">
            <div style="margin-bottom: 30px; color: #00a252; font-size: 23px">
              奥普斯®美白牙膏
            </div>
            <div>使用说明</div>
          </div>
          <div class="shop">
            <div style="margin-bottom: 30px; color: #00b2a9; font-size: 23px">
              奥普斯®抗敏牙膏
            </div>
            <div>使用说明</div>
          </div>
        </div>
        <div class="shop_name" style="margin-top: 18px">
          <div class="shop">
            <div>成人及2岁及以上儿童:</div>
            <div class="content">
              彻底刷牙，最好是用餐后或至少一天两次，或遵循牙医或医生指示。指导6岁以下儿童养成良好的刷牙和漱口习惯(以减少吞咽)。必要时监督孩子，直到能够在没有监督的情况下使用。
            </div>
            <div style="margin-top: 50px">2岁以下儿童:</div>
            <div class="content">请咨询牙医或医生。</div>
            <div style="margin-top: 45px">成分</div>
          </div>
          <div class="shop">
            <div>成人及12岁及以上儿童:</div>
            <div class="content">
              在软毛牙刷上涂抹至少2.5厘米的产品。每天两次(早晚)彻底刷牙至少1分钟，或遵循牙医或医生的指示。
            </div>
            <div style="margin-top: 75px">12岁以下儿童:</div>
            <div class="content">请咨询牙医或医生。</div>
            <div style="margin-top: 45px">成分</div>
          </div>
        </div>
        <div class="shop_name" style="margin-top: 25px">
          <div class="shop">
            <div>活性成分:</div>
            <div class="content">氟化钠0.25%w/w(抗蛀牙)</div>
            <div style="margin-top: 31px">非活性成分:</div>
            <div class="content">
              甘油，水(水)，二氧化硅，山梨醇，木糖醇，香精(芳香)，泊洛沙姆，十二烷基硫酸钠，卡波姆，FD&C蓝色#1 (Cl 42090)， FD&C黄色#5 (Cl19140)，苯甲酸钠，氢氧化钠，Sparkle (Cl 77019, Cl77891)，三氯蔗糖，黄原胶。
            </div>
            <div style="margin-top: 47px">用途</div>
          </div>
          <div class="shop">
            <div>活性成分:</div>
            <div class="content">氟化钠0.25%w/w(抗蛀牙)，硝酸钾5%w/w。</div>
            <div style="margin-top: 31px">非活性成分:</div>
            <div class="content">
              水、二氧化硅、木糖醇、甘油、山梨醇、香精、泊洛沙姆407、月桂基硫酸钠、卡波姆、苯甲酸钠、氢氧化钠、三氯蔗糖、黄原胶、FD&C蓝色1号(CI42090)、FD&C黄色5号(CI 19140)
            </div>
            <div style="margin-top: 47px">用途</div>
          </div>
        </div>
        <div class="shop_name" style="margin-top: 27px">
          <div class="shop">
            <div style="font-size: 14px">有助于预防蛀牙。</div>
            <div style="margin-top: 50px">警告</div>
          </div>
          <div class="shop">
						<div style="font-size: 14px">
						  有助于预防蛀牙。
						</div>
            <div style="font-size: 14px">
              帮助减少牙齿对冷，热，酸，甜食接触的疼痛敏感。
            </div>
            <div style="margin-top: 50px">警告</div>
          </div>
        </div>
        <div class="shop_name" style="margin-top: 25px">
          <div class="shops">
            请置于6岁以下儿童接触不到的地方。如果不小心吞下超过刷牙用量，请立即寻求医疗帮助或联系中毒控制中心。
          </div>
          <div class="shops">
            请置于6岁以下儿童接触不到的地方。如果不小心吞下超过刷牙用量，请立即寻求医疗帮助或联系中毒控制中心。敏感的牙齿意味着可能患有严重口腔问题，需要牙医的及时护理。如果问题持续或恶化，建议到医院就诊。除非牙医或医生建议，否则不要使用本产品超过4周。
          </div>
        </div>
        <!-- <div class="explain_left">
          <div class="title">奥普斯®美白牙膏</div>
          <div class="explain_text">使用说明</div>
          <div class="textone">成人及2岁及以上儿童:</div>
          <div class="explasone">
            彻底刷牙，最好是用餐后或至少一天两次，或遵循牙医或医生指示。指导6岁以下儿童养
            成良好的刷牙和漱口习惯(以减少吞咽)。必要时监督孩子，直到能够在没有监督的情况
            下使用。
          </div>
          <div class="texttwo">2岁以下儿童:</div>
          <div class="explastwo">请咨询牙医或医生。</div>
          <div class="ingredient">成分</div>
          <div class="ingredientnameone">活性成分:</div>
          <div class="ingredienttextone">氟化钠0.25%w/w(空腔)</div>
          <div class="ingredientnametwo">非活性成分:</div>
          <div class="ingredienttexttwo">
            甘油，水(水)，二氧化硅，山梨醇，木糖醇，香精(芳香)，泊洛沙姆，十二烷基硫酸钠，卡波
            姆，FD&C蓝色#1 (Cl 42090)， FD&C黄色#5 (Cl
            19140)，苯甲酸钠，氢氧化钠，Sparkle (Cl 77019, Cl
            77891)，三氯蔗糖，黄原胶。
          </div>
          <div class="ingredientnamether">用途</div>
          <div class="ingredienttextther">帮助预防蛀牙。</div>
          <div class="ingredientnamether">警告</div>
          <div class="ingredienttextther">
            请置于6岁以下儿童接触不到的地方。如果不小心吞下超过刷牙用量，请立即寻求医疗
            帮助或联系中毒控制中心。
          </div>
        </div> -->

        <!-- <div class="explain_left">
          <div class="title" style="color: #00b2a9">奥普斯®抗敏牙膏</div>
          <div class="explain_text">使用说明</div>
          <div class="textone">成人及2岁及以上儿童:</div>
          <div class="explasone">
            在软毛牙刷上涂抹至少2.5厘米的产品。每天两次(早晚)彻底刷牙至少1分钟，或遵循牙
            医或医生的指示。
          </div>
          <div class="texttwo">12岁以下儿童:</div>
          <div class="explastwo">请咨询牙医或医生。</div>
          <div class="ingredient">成分</div>
          <div class="ingredientnameone">活性成分:</div>
          <div class="ingredienttextone">
            氟化钠0.25%w/w(反腔)，硝酸钾5%w/w。
          </div>
          <div class="ingredientnametwo">非活性成分:</div>
          <div class="ingredienttexttwo">
            水、二氧化硅、木糖醇、甘油、山梨醇、香精、泊洛沙姆407、月桂基硫酸钠、卡波姆、苯甲
            酸钠、氢氧化钠、三氯蔗糖、黄原胶、FD&C蓝色1号(CI
            42090)、FD&C黄色5号(CI 19140)
          </div>
          <div class="ingredientnamether">用途</div>
          <div class="ingredienttextther">
            帮助减少牙齿对冷，热，酸，糖或接触的疼痛敏感。
          </div>
          <div class="ingredientnamether">警告</div>
          <div class="ingredienttextther">
            请置于6岁以下儿童接触不到的地方。如果不小心吞下超过刷牙用量，请立即寻求医疗
            帮助或联系中毒控制中心。敏感的牙齿意味着可能患有严重口腔问题，需要牙医的及时
            护理。如果问题持续或恶化，建议到医院就诊。除非牙医或医生建议，否则不要使用本产
            品超过4周。
          </div>
        </div> -->
      </div>
      <div class="bittomes">
        <div class="textads">*非Ultradent Products, Inc.的注册商标</div>
        <div class="textads">*如果使用4周后仍然敏感，请咨询牙医。</div>
      </div>
    </div>
    <div class="tansuos">
      <div class="tansuos_center">
        <div class="flepos_left">
          <img
            class="flepos_img"
            src="../assets/img/third-banner-model.png"
            alt=""
          />
        </div>
        <div class="flepos_left">
          <div class="text">
            了解为什么奥普斯牙齿美白是专业牙齿美白的领导者，
            请开始你的旅程，寻找最亮白牙齿和最自信笑容。
          </div>
          <div class="btns" @click="topaobans('/principle')">探索牙齿美白</div>
        </div>
      </div>
    </div>
    <!-- <div class="bottoms">
      <div class="bottomsbg"></div>
      <div class="displays">
        <div class="bottomsbg_left">
          <div class="title">快速比较美白</div>
          <div class="center">看看什么适合你的生活方式</div>
          <div class="btns" @click="topaobans('/skinwhitening')">
            比较牙齿美白产品
          </div>
        </div>
        <img class="bottomimg" src="../assets/img/yaoyaoyao.png" alt="" />
      </div>
    </div> -->
    <div class="goodes">
      <div class="goodes_title">更多美白选择</div>
      <div class="centers">
        <img
          class="centersone"
          src="../assets/img/tuopanaaa1.png"
          alt=""
          @click="goyagaoshangcheng(1)"
        />
        <img
          class="centerstwo"
          src="../assets/img/shangchengasa.png"
          alt=""
          @click="goyagaoshangcheng(2)"
        />
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import tabBar from "@/components/tabBar.vue";
import triangle from "@/components/triangle.vue";
import footers from "@/components/footer.vue";
import { getshopid } from "../request/homeApi";
export default {
  data() {
    return {
      cardIndex: 0,
      shopids: [],
    };
  },
  components: {
    tabBar,
    triangle,
    footers,
  },
  mounted() {
    this.getshop();
  },
  methods: {
    goyagaoshangcheng(index) {
      if (index == 1) {
        this.$router.push({ path: "/skinwhitening" });
      }
      if (index == 2) {
				// this.$message.error('此功能正在建设中，敬请期待')
				// return false
        this.$router.push({ path: "/OpusMall" });
      }
    },
    //获取商品id
    async getshop() {
      let res = await getshopid();
      console.log(res);
      this.shopids = res.result.split(",");
    },
    topaobans(url) {
      this.$router.push({
        path: url,
      });
    },
    lsitads(index) {
			// this.$message.error('此功能正在建设中，敬请期待')
			// return false
      this.$router.push({
        path: "/shopdet",
        query: { id: index },
      });
      console.log(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.scheme {
  width: 100%;
  position: relative;
  padding-bottom: 290px;
  .scheme_center {
    max-width: 1250px;
    margin: 0 auto;

    .titles {
      padding: 72px 0;
      text-align: center;
      font-size: 36px;
      color: #535353;
    }
    .centersimg {
      display: flex;
			flex-flow: row;
      justify-content: center;
      .imgs_left {
        margin-left: 60px;
        .imgs_leftads {
          // width: 536px;
          // height: 400px;
        }
        .imgadsbtns {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #00a252;
          font-size: 24px;
          padding-left: 10px;

          .carts {
            width: 121px;
            height: 46px;
            text-align: center;
            line-height: 46px;
            background: #ffffff;
            border: 1px solid #00a252;
            border-radius: 3px;
            font-size: 16px;
            cursor: pointer;
          }
          .bgsborder {
            border: 1px solid #00b2a9;
          }
        }
        .bgs {
          color: #00b2a9;
        }
        .skus {
          margin-top: 18px;
          padding: 18px 0;
          font-size: 20px;
          color: #727272;
          border-top: 1px solid #e7e7e7;
          border-bottom: 1px solid #e7e7e7;
          padding-left: 10px;
        }
        .uls {
          margin-top: 32px;
          padding-left: 20px;
          line-height: 35px;
          .li {
            list-style-type: disc;
            font-size: 16px;
            color: #535353;
          }
        }
      }
    }
  }
}
.schemecens {
  width: 100%;
  background: #00b2a9;
  .schemecens_center {
    max-width: 1250px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    .pngstg {
      width: 583px;
      height: 439px;
      position: absolute;
      left: 0;
      right: 0;
      top: -236px;
      margin: auto;
      z-index: 99;
    }
    .topimg {
      width: 243px;
      margin-top: 233px;
    }
    .centertitle {
      margin-top: 45px;
      font-size: 40px;
      color: #ffffff;
    }
    .centertext {
      margin-top: 50px;
      font-size: 24px;
      color: #ffffff;
      padding-bottom: 102px;
    }
  }
}
.schennums {
  width: 100%;
  background: #f7f6f6;
  .schennums_center {
    max-width: 1250px;
    height: 512px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    .svjleft {
      .title {
        margin-top: 130px;
        font-size: 36px;
        color: #535353;
      }
      .texts {
        width: 734px;
        margin-top: 35px;
        font-size: 20px;
        color: #535353;
        line-height: 40px;
      }
    }
    .imgtext {
      width: 634px;
      position: absolute;
      right: -20px;
      bottom: -724px;
    }
  }
}
.nuvone {
  width: 100%;
  height: 580px;
  .nuvone_center {
    max-width: 1250px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .toothpasteimg {
      width: 535px;
      height: 507px;
      display: block;
    }
    .toothpasteright {
      width: 630px;
      margin-left: 80px;
      margin-bottom: 185px;
      .title {
        font-size: 36px;
        color: #535353;
      }
      .texts {
        margin-top: 35px;
        font-size: 20px;
        color: #535353;
      }
    }
  }
}
.townie {
  width: 100%;
  background: #f7f6f6;
  height: 600px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  .townie_center {
    max-width: 1250px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .townie_left {
      width: 50%;
      height: 100%;
      .titles {
        margin-top: 130px;
        font-size: 36px;
        color: #535353;
      }
      .texts {
        margin-top: 35px;
        font-size: 20px;
        color: #535353;
      }
    }
    .townie_right {
      width: 535px;
      height: 100%;
      margin-left: 97px;
      position: relative;
      .townieimg {
        width: 535px;
        height: 666px;
        position: absolute;
        top: -67px;
      }
    }
  }
}
.explain {
  width: 100%;
  margin-top: 72px;
  .bittomes {
    width: 100%;
    padding: 64px 0 72px 0;
    box-sizing: border-box;
    .textads {
      text-align: center;
      font-size: 14px;
      line-height: 30px;
      color: #b1b3b3;
    }
  }
  .explain_center {
    width: 1250px;
    margin: 0 auto;
    .shop_name {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      .shop {
        width: 548px;
        padding-bottom: 18px;
        box-sizing: border-box;
        border-bottom: 1px solid #e7e7e7;
        color: #727272;
        font-size: 20px;
        .content {
          font-size: 14px;
          margin-top: 22px;
        }
      }
      .shops {
        width: 548px;
        color: #727272;
        font-size: 14px;
      }
    }
  }
  // .explain_center {
  //   width: 1250px;
  //   margin: 0 auto;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   .explain_left {
  //     width: 548px;
  //     .title {
  //       margin-top: 72px;
  //       font-size: 24px;
  //       color: #00a252;
  //     }
  //     .explain_text {
  //       width: 100%;
  //       padding-bottom: 18px;
  //       border-bottom: 1px solid #e7e7e7;
  //       margin-top: 30px;
  //       font-size: 20px;
  //       color: #727272;
  //     }
  //     .textone {
  //       margin-top: 18px;
  //       font-size: 20px;
  //       color: #727272;
  //     }
  //     .explasone {
  //       margin-top: 21px;
  //       font-size: 14px;
  //       color: #727272;
  //     }
  //     .texttwo {
  //       margin-top: 50px;
  //       font-size: 20px;
  //       color: #727272;
  //     }
  //     .explastwo {
  //       margin-top: 22px;
  //       font-size: 14px;
  //       color: #727272;
  //     }
  //     .ingredient {
  //       width: 100%;
  //       padding-bottom: 18px;
  //       border-bottom: 1px solid #e7e7e7;
  //       margin-top: 45px;
  //       font-size: 20px;
  //       color: #727272;
  //     }
  //     .ingredientnameone {
  //       margin-top: 25px;
  //       font-size: 20px;
  //       color: #727272;
  //     }
  //     .ingredienttextone {
  //       margin-top: 22px;
  //       font-size: 14px;
  //       color: #727272;
  //     }
  //     .ingredientnametwo {
  //       margin-top: 30px;
  //       font-size: 20px;
  //       color: #727272;
  //     }
  //     .ingredienttexttwo {
  //       margin-top: 22px;
  //       font-size: 14px;
  //       color: #727272;
  //     }
  //     .ingredientnamether {
  //       width: 100%;
  //       padding-bottom: 18px;
  //       border-bottom: 1px solid #e7e7e7;
  //       margin-top: 45px;
  //       font-size: 20px;
  //       color: #727272;
  //     }
  //     .ingredienttextther {
  //       margin-top: 25px;
  //       font-size: 14px;
  //       color: #727272;
  //     }
  //   }
  // }
}
.tansuos {
  width: 100%;
  background: #f8f8f8;
  height: 421px;
  .tansuos_center {
    height: 421px;
    max-width: 1250px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .flepos_left {
      width: 50%;
      position: relative;
      .flepos_img {
        width: 580px;
        position: absolute;
        top: -140px;
        left: -42px;
        clip: rect(0px, 587px, 563px, 0px);
      }
      .text {
        margin-top: 136px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        color: #535353;
      }
      .btns {
        cursor: pointer;
        width: 189px;
        height: 48px;
        text-align: center;
        line-height: 48px;
        background: #00b2a9;
        border-radius: 2px;
        font-size: 16px;
        color: #ffffff;
        margin: 66px auto;
      }
    }
  }
}
.bottoms {
  width: 100%;
  height: 595px;
  position: relative;
  .bottomsbg {
    height: 100%;
    background: #f8f8f8;
    -webkit-clip-path: polygon(0 0, 36% 0, 87% 100%, 0% 100%);
    clip-path: polygon(0 0, 36% 0, 87% 100%, 0% 100%);
  }
  .displays {
    max-width: 1250px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    .bottomsbg_left {
      width: 340px;
      text-align: center;
      margin-left: 170px;
      .title {
        font-size: 40px;
        color: #535353;
      }
      .center {
        white-space: nowrap;
        margin-top: 32px;
        font-size: 24px;
        color: #535353;
      }
      .btns {
        width: 200px;
        height: 46px;
        margin: 0 auto;
        margin-top: 47px;
        text-align: center;
        line-height: 46px;
        background: #00a3e0;
        border-radius: 3px;
        font-size: 16px;
        color: #ffffff;
      }
    }
    .bottomimg {
      width: 705px;
      height: 301px;
      margin-left: 127px;
    }
  }
}
.goodes {
  width: 100%;
  padding-bottom: 88px;
  .goodes_title {
    width: 100%;
    margin-top: 72px;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #535353;
  }
  .centers {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 94px;
    img {
      width: 600px;
      height: 226px;
      cursor: pointer;
    }
    .centersone {
      margin-right: 50px;
    }
  }
}
</style>