<template>
	<div class="FAQ">
		<tabBar :cardIndex="5" />
		<div class="faqhomes">
			<img src="../../assets/img/xie/FAQ.png" />
			<div class="homecont">
				<div style="font-size: 36px;display: flex;flex-flow: column;justify-content: center;align-items: center;">
					<span style="font-size: 80px;">FAQ</span>
					<span>常见问题</span>
				</div>
				<div style="margin-top: 40px;">
					<div>
						<span style="font-size: 24px;display: inline-block;margin-right: 12px;">请描述你的问题</span>
						<span style="font-size: 12px;">例如牙齿敏感，怀孕期间的美白等</span>
					</div>
					<div style="display: flex;margin-top: 13px;">
						<div class="sous">
							<el-input v-model="parsm.keyWord" @keyup.enter.native='screen'></el-input>
						</div>
						<div class="sous_but" @click="screen">
							<i class="el-icon-d-arrow-right"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="content">
			<el-row class="content_li" v-for="(item,index) in conten" :key='index'>
				<el-col :span="4">
					<div class="grid-content"></div>
				</el-col>
				<el-col :span="16">
					<div class="content_con">
						<div class="cont" @click="show(item)">
							<i class="el-icon-arrow-up" v-if="item.type"></i>
							<i class="el-icon-arrow-down" v-else></i>
							<span style="color: #535353;font-size: 20px;">{{item.questions}}</span>
						</div>
						<div style="color: #727272;font-size: 16px;margin-bottom: 30px;" v-show="item.type">
							<div>{{item.answer}}</div>
						</div>
					</div>
				</el-col>
				<el-col :span="4">
					<div class="grid-content"></div>
				</el-col>
			</el-row>
		</div>
		<el-row class="faqfoot">
		  <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
		  <el-col :span="16">
				<div style="display: flex;flex-flow: row;justify-content: space-between;align-items: flex-end;">
					<div class="foot_img">
						<img src="../../assets/img/Dentist.png" />
					</div>
					<div class="foot_right">
						<img class="foot_rigimg" src="../../assets/img/xie/dizhi.png" />
						<div style="font-size: 36px;margin-top: 25px;font-weight: bold;">从今天开始美白</div>
						<div style="text-align: center;font-size: 24px;margin-top: 46px;">搜索寻找附近的皓齿美白大师，<br/>量身定制最适合您的牙齿美白方案！</div>
						<div class="foot_but" @click="routes">寻找您附近的美白牙医</div>
					</div>
				</div>
			</el-col>
		  <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
		</el-row>
		<footers class="foos"></footers>
	</div>
</template>

<script>
	import tabBar from "@/components/tabBar.vue";
	import triangle from "@/components/triangle.vue";
	import footers from "@/components/footer.vue";
	import { getFAQlist } from "@/request/xie";
	export default{
		components:{
			tabBar,
			triangle,
			footers
		},
		data(){
			return{
				conten:[],
				parsm:{
					pageNumber:1,
					sort: 'createTime',
					pageSize:300,
					keyWord:"",
				}
			}
		},
		mounted() {
			if(this.$route.query.questions){
				this.parsm.keyWord=this.$route.query.questions
				this.getlists()
			}else{
				this.getlist()
			}
		},
		methods:{
			//获取FAQ列表
			async getlist(){
				let res=await getFAQlist(this.parsm)
				this.conten=res.result.records
				this.conten.forEach((item,index)=>{
					item.type=false
				})
				//console.log(this.conten)
			},
			//筛选
			screen(){
				if(this.parsm.keyWord==''){
					this.getlist()
				}else{
					this.getlists()
				}
			},
			//筛选FAQ列表
			async getlists(){
				let res=await getFAQlist(this.parsm)
				this.conten=res.result.records
				this.conten.forEach((item,index)=>{
					item.type=true
				})
				//console.log(this.conten)
			},
			//显示隐藏内容
			show(item){
				this.$set(item,'type',!item.type)
				this.$forceUpdate()
			},
			//跳转寻找牙医
			routes(){
				this.$router.push('./lookingdentist')
			}
		}
	}
</script>

<style scoped lang="scss">
	.grid-content {
			border-radius: 4px;
			min-height: 36px;
		}
::v-deep.FAQ{
	width: 100%;
	overflow-x: hidden;
	.faqhomes{
		margin-top: 80px;
		width: 100%;
		height: 596px;
		position: relative;
		display: flex;
		flex-flow: row;
		justify-content: center;
		img{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.homecont{
			margin-top: 80px;
			color: #fff;
			position: relative;
			z-index: 2;
			.sous{
				width: 598px;
				height: 46px;
				.el-input__inner{
					height: 46px;
					border-radius: 2px;
					border: none;
				}
			}
			.sous_but{
				width: 46px;
				height: 46px;
				background-color: #00A252;
				margin-left: 4px;
				border-radius: 2px;
				display: flex;
				flex-flow: row;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
		}
	}
	.content{
		margin-top: 66px;
		width: 100%;
		.content_li{
			.content_con{
				max-width: 1242px;
				i{
					color: #0075AA;
					font-size: 28px;
					margin-right: 5px;
				}
				.cont{
					display: flex;
					align-items: center;
					cursor: pointer;
					margin-top: 25px;
					margin-bottom: 25px;
				}
			}
		}
		.content_li:first-child{
			.cont{
				margin-top: 0;
			}
		}
		.content_li:nth-child(even){
			background-color: #F4F4F4;
		}
	}
	.faqfoot{
		height: 693px;
		width: 100%;
		color: #535353;
		position: relative;
		z-index: 1;
		.foot_img{
			img{
				width: 519px;
				height: 619px;
				display: block;
				margin-top: 74px;
			}
		}
		.foot_right{
			width: 513px;
			//height: 619px;
			//margin-top: 74px;
			display: flex;
			flex-flow: column;
			align-items: center;
			.foot_rigimg{
				width: 77px;
				height: 96px;
				//margin-top: 170px;
			}
			.foot_but{
				width: 245px;
				height: 48px;
				border-radius: 2px;
				background-color: #00A3E0;
				color: #fff;
				font-size: 16px;
				text-align: center;
				line-height: 48px;
				cursor: pointer;
				margin-top: 60px;
				margin-bottom: 62px;
			}
		}
	}
	.foos::before{
		content: "";
		position: absolute;
		z-index: 0;
		height: 693px;
		bottom: 100%;
		left: 38.8%;
		width: 100%;
		background: linear-gradient(hsla(0,0%,93%,.2),hsla(0,0%,85%,.2));
		transform: skew(-52deg);
	}
}
</style>
