<template>
	<div class="dentaldet">
		<tabBar :cardIndex="3"></tabBar>
		<div class="homes"></div>
		<el-row class="mapads">
		  <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
		  <el-col :span="16">
				<div class="titles">{{dentdata.organizationName}}</div>
				<div class="content">{{dentdata.intro}}</div>
			</el-col>
		  <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
		</el-row>
		<el-row class="mapadsa">
		  <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
		  <el-col :span="16">
				<div class="doctor">
					<div class="doc_img">
						<img src="../assets/img/xie/yayixx1.png" />
					</div>
					<div class="doctors">
						<div style="font-size: 36px;">联系信息</div>
						<div class="doc_right" style="margin-top: 33px;">
							<span></span>
							<span>医生姓名：{{dentdata.name}}</span>
						</div>
						<div class="doc_right">
							<span></span>
							<span>联系电话：{{dentdata.linkPhone}}</span>
						</div>
						<div class="doc_right">
							<span></span>
							<span>所属地区：{{dentdata.consigneeAddressPath}}</span>
						</div>
						<div class="doc_right" style="align-items: flex-start;">
							<span style="margin-top: 8px;"></span>
							<span style="width: 90%;">详细地址：{{dentdata.detail}}</span>
						</div>
					</div>
				</div>
			</el-col>
		  <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
		</el-row>
		<el-row>
		  <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
		  <el-col :span="16">
				<div class="clinic">
					<div class="title">诊所详情</div>
					<div class="clin_img">
						<div v-for="(item,index) in dentdata.img" :key='index' class="clin_img_a" @click="detimg(item)">
							<div>
								<i class="el-icon-zoom-in"></i>
							</div>
							<img :src="item" />
						</div>
					</div>
				</div>
			</el-col>
		  <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
		</el-row>
		<el-dialog :visible.sync="dialogVisible" width="800px">
		  <img :src="dentaimg" style="width: 100%;" />
		</el-dialog>
		<footers></footers>
	</div>
</template>

<script>
	import tabBar from "@/components/tabBar.vue";
	import footers from "@/components/footer.vue";
	import { getdendet } from "@/request/xie";
	export default{
		components: {
		  tabBar,
			footers
		},
		data(){
			return{
				id:'',
				dentdata:{},
				dialogVisible:false,
				dentaimg:''
			}
		},
		mounted() {
			if(this.$route.query.id){
				this.id=this.$route.query.id
				this.getdentxx()
			}
		},
		methods:{
			//获取牙医详情
			async getdentxx(){
				let res=await getdendet(this.id)
				this.dentdata=res.result
				let sk=this.dentdata.img.split(',')
				this.dentdata.img=sk
			},
			detimg(item){
				this.dialogVisible=true
				this.dentaimg=item
			}
		}
	}
</script>

<style scoped lang="scss">
	.grid-content {
			border-radius: 4px;
			min-height: 36px;
		}
	.dentaldet{
		.homes{
			width: 100%;
			height: 678px;
			background-image: url('../assets/img/xie/yayixx.png');
			background-size: 100% 100%;
			position: relative;
			margin-top: 80px;
		}
		.mapads{
			margin-top: 117px;
			.titles{
				color: #0076AE;
				font-size: 48px;
				text-align: center;
			}
			.content{
				margin-top: 67px;
				color: #535353;
				font-size: 16px;
				text-align: center;
				line-height: 36px;
			}
		}
		.mapadsa{
			height: 429px;
			margin-top: 211px;
			background-color: #F5F5F5;
			.doctor{
				display: flex;
				position: relative;
				.doc_img{
					width: 611px;
					margin-right: 86px;
					img{
						position: absolute;
						top: -169px;
						left: 0;
						width: 611px;
						height: 598px;
					}
				}
				.doctors{
					height: 429px;
					display: flex;
					flex-flow: column;
					justify-content: center;
					color: #535353;
					font-size: 16px;
					.doc_right{
						display: flex;
						align-items: center;
						margin-bottom: 10px;
						span:first-child{
							width: 8px;
							height: 8px;
							background-color: #535353;
							border-radius: 50%;
							display: inline-block;
							margin-right: 5px;
						}
					}
				}
			}
		}
		.clinic{
			margin-top: 83px;
			margin-bottom: 100px;
			.title{
				text-align: center;
				font-size: 40px;
				color: #333333;
			}
			.clin_img{
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				margin-top: 70px;
				.clin_img_a{
					cursor: pointer;
					margin-bottom: 10px;
					position: relative;
					div{
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background-color: rgba(0, 0, 0, .6);
						display: none;
						text-align: center;
						i{
							position: relative;
							top: 50%;
							transform: translateY(-50%);
							color: #fff;
							font-size: 32px;
						}
					}
				}
				.clin_img_a:hover{
					div{
						display: block;
					}
				}
				img{
					width: 400px;
					height: 300px;
					//margin-right: 26px;
					
				}
			}
		}
	}
	.clin_img::after{
		content: '';
		width: 400px;
	}
	::v-deep .el-dialog__header{
		display: none;
	}
</style>
