<template>
  <div class="lookingdent">
    <tabBar :cardIndex="3" :mouseenterIndex="20" />
    <div class="looking">
      <div class="looking_background">
        <!-- <div class="neibucenter">
          <triangle :back="'#ffffff'" style="z-index: 0"></triangle>
        </div> -->
      </div>
      <div class="lookeng_center">
        <div class="lookingname">找到你附近的奥普斯 牙齿美白中心</div>
        <div class="lookingsher">
          <input
            class="left"
            type="text"
            v-model="parms.keyWord" @keyup.enter='alllook'
            placeholder="输入医生姓名、诊所（机构）名称、城市名称搜索诊所"
          />
          <div class="btnsa" @click="alllook">搜索</div>
        </div>
        <div class="mapads">
          <div id="allmap" class="allmap" ref="myMap"></div>
          <div class="lookall" @click="alllook">查看所有诊所</div>
        </div>
        <div class="wayname">为什么选择奥普斯</div>
        <div class="waytext">
          Opalescence™奥普斯®作为专业牙齿美白领导者，不仅有日常使用的美白护理产品，更有医生诊室使用的专业美白产品。直接从您的牙医或其他有执照的牙科专业人士处，可确保您获得最适合您需求的
          Opalescence™奥普斯®牙齿美白体验。
        </div>
        <div class="buttoms"></div>
      </div>
    </div>
    <div class="navadlist">
      <div class="navadlist_center">
        <div class="navadlist_left">
          <div class="toptitle">
            当您在牙医或正规渠道购买奥普斯®牙齿美白产品时，您可以确保您获得的牙齿美白产品：
          </div>
          <ul class="ul">
            <li class="li">奥普斯®牙齿美白产品正品保证</li>
            <li class="li">产品包装使用的都是环保型、可回收利用材料</li>
            <li class="li">
              有效美白牙齿
            </li>
            <li class="li">满足您的个人口腔健康需求</li>
            <li class="li">
              由专业牙科医生推荐，确保您的口腔健康
            </li>
          </ul>
        </div>
        <img class="navadlist_right" src="../assets/img/Dentist.png" alt="" />
      </div>
    </div>
    <div class="talluns">
      <img
        class="tallunsimg"
        src="../assets/img/compare-dentistandpatient.png"
        alt=""
      />
      <div class="textp">
        与您的牙医讨论奥普斯®牙齿美白，了解我们提供的所有牙齿美白套装。通过这种方式，您可以确保您的牙齿美白治疗适合您、您的生活方式和您的预算。
      </div>
    </div>
    <div class="displays">
      <div class="bottomsbg_left">
        <div class="title">快速比较美白</div>
        <div class="center">看看什么适合你的生活方式</div>
        <div class="btns" @click="topages">比较牙齿美白产品</div>
      </div>
      <img class="bottomimg" src="../assets/img/ads7.png" alt="" />
    </div>
		<router-link to="dentaldet">ss</router-link>
    <footers class="foos"></footers>
  </div>
</template>

<script>
import tabBar from "@/components/tabBar.vue";
import triangle from "@/components/triangle.vue";
import footers from "@/components/footer.vue";
import { getdentistlist } from "../request/homeApi";
import gisGreen from "@/assets/img/diangwei143.png";
export default {
  components: {
    tabBar,
    triangle,
    footers,
  },
  data() {
    return {
      type: "tab",
      center: {
        lng: 116.405813,
        lat: 39.914059,
      },
      parms: {
        lat: "",
        lon: "",
        city:"",
        keyWord: "",
      },
    };
  },
  mounted() {
    this.posidingweis();
    this.initMap(6);
  },
  methods: {
    //查看所有牙医
    alllook() {
      this.$router.push("dentists?keyWord=" + this.parms.keyWord);
    },
    topages() {
			// this.$message.error('此功能正在建设中，敬请期待')
			// return false
      this.$router.push("/opusmall");
    },
    initMap(index) {
      this.createMap(index); //创建地图
      this.setMapEvent(); //设置地图事件
      this.addMapControl(); //向地图添加控件
      this.addMarker(); //向地图中添加marker
    },
    posidingweis() {
      let that = this;
      const geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function (r) {
        console.log(r);
        that.parms.lon = r.longitude;
        that.parms.lat = r.latitude;
        that.center.lng = r.longitude;
        that.center.lat = r.latitude;
        that.parms.city = r.address.city;
        that.initMap(12);
        // console.log(r.longitude);
        // if(this.getStatus() == BMAP_STATUS_SUCCESS){
        //    const{lat =null, lng=null} = r.point;
        // };
      });
    },
    createMap(index) {
      var map = new BMap.Map(this.$refs.myMap); //在百度地图容器中创建一个地图
      //  var point = new BMap.Point(116.405813,39.914059);//定义一个中心点坐标
      var point = new BMap.Point(this.center.lng, this.center.lat); //定义一个中心点坐标
      map.centerAndZoom(point, index); //设定地图的中心点和坐标并将地图显示在地图容器中
      window.map = map; //将map变量存储在全局
    },
    setMapEvent() {
      map.enableDragging(); //启用地图拖拽事件，默认启用(可不写)
      map.enableScrollWheelZoom(); //启用地图滚轮放大缩小
      map.enableDoubleClickZoom(); //启用鼠标双击放大，默认启用(可不写)
      map.enableKeyboard(); //启用键盘上下左右键移动地图 ZiM96rGtlsmVlzXZRqZK59kx0hV2Pawu
    },
    addMapControl() {
      //向地图中添加缩放控件
      var ctrl_nav = new BMap.NavigationControl({
        anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
        type: BMAP_NAVIGATION_CONTROL_LARGE,
      });
      map.addControl(ctrl_nav);
      //向地图中添加缩略图控件
      var ctrl_ove = new BMap.OverviewMapControl({
        anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
        isOpen: 1,
      });
      map.addControl(ctrl_ove);
      //向地图中添加比例尺控件
      var ctrl_sca = new BMap.ScaleControl({ anchor: BMAP_ANCHOR_BOTTOM_LEFT });
      map.addControl(ctrl_sca);
    },
    addMarker() {
      getdentistlist(this.parms)
        .then((res) => {
          if (res.code == 200) {
						console.log(res)
            var markerArr = [];
            res.result.forEach((item) => {
							item.consigneeAddressPath=item.consigneeAddressPath.replace(/ ,/g,'')
              let obg = {
                title: item.organizationName,
                point: item.lon,
                address: item.consigneeAddressPath + item.detail,
                contact: item.name,
                img: gisGreen,
                linkPhone: item.linkPhone,
								ids:item.id,
								levelName:item.levelName?item.levelName:''
              };
              markerArr.push(obg);
            });
            // var point = new Array(); //定义数组标注经纬信息

            // var marker = new Array(); //定义数组点对象信息

            // var info = new Array(); //定义悬浮提示信息
            //设置icon信息
            for (var i = 0; i < markerArr.length; i++) {
              //遍历
              var infoA = markerArr[i].point.split(",")[0]; //分割|
              var infoB = markerArr[i].point.split(",")[1];
              var myIcon_vd1 = new BMap.Icon(
                markerArr[i].img,
                new BMap.Size(32, 32)
              );
              // point[i] = new window.BMap.Point(infoA, infoB);
              // marker[i] = new window.BMap.Marker(point[i], {
              //   icon: myIcon_vd1,
              // }); //把icon和坐标添加到Marker中
							let marker = new BMap.Marker(new BMap.Point(infoA, infoB),{icon:myIcon_vd1});
              map.addOverlay(marker);
              //marker[i].setAnimation(BMAP_ANIMATION_BOUNCE);

              // var label = new window.BMap.Label(markerArr[i].title, {
              //   offset: new window.BMap.Size(20, -10),
              // });
              // label.setStyle({
              //   //设置提示框的样式
              //   color: "blue",
              //   borderRadius: "5px",
              //   borderColor: "#ccc",
              //   // color: "#000",
              //   fontSize: "14px",
              //   backgroundColor: "#fff",
              //   border: "1px solid #cccccc",
              //   fontWeight: "bold",
              //   padding: "2px 6px",
              // });
              // marker[i].setLabel(label);
              let content = "<p style='font-size:12px;line-height:1.8em;'>" +
                  markerArr[i].contact +
									"<span style='margin-left:10px'>"+markerArr[i].levelName+"</span>"+
									"</br>"+"<a style='color:#09a9e2;cursor: pointer;' id='markerbtn'>"+markerArr[i].title+"</a>" +
                  "</br>" +
                  markerArr[i].address +
									"</br> 联系电话：" +
									markerArr[i].linkPhone +
                  "</p>";
							addClickHandler(content, marker,markerArr[i].ids);
            }
						//给点位添加点击事件
							function addClickHandler(content, marker,ids) {
								marker.addEventListener("mouseover", function (e) {
									openInfo(content, e,ids);
								});
							}
							let thia=this
							//打开信息窗口
							function openInfo(content, e,ids) {
								var p = e.target;
								var point = new BMap.Point(p.getPosition().lng, p.getPosition().lat);
								var infoWindow = new BMap.InfoWindow(content);  // 创建信息窗口对象 
								map.openInfoWindow(infoWindow, point); //开启信息窗口
												//判断窗口的打开状态
								if (!infoWindow.isOpen()) {
														//如果没有打开，则监听打开事件，获取按钮，添加事件
									infoWindow.addEventListener("open", function () {
										document.getElementById("markerbtn").onclick = function (e) {
											thia.$router.push({
												name:'dentaldet',
												query:{id:ids}
											})
										}
									})
								} else {//如果已经打开，直接获取按钮，添加事件
									document.getElementById("markerbtn").onclick = function (e) {
										thia.$router.push({
											name:'dentaldet',
											query:{id:ids}
										})
									}
								}
							}
            // marker[0].addEventListener("mouseover", function () {
            //   this.openInfoWindow(info[0]); //悬浮监听提示方法
            // });
            // marker[1].addEventListener("mouseover", function () {
            //   this.openInfoWindow(info[1]); //悬浮监听提示方法
            // });
            // marker[2].addEventListener("mouseover", function () {
            //   this.openInfoWindow(info[2]); //悬浮监听提示方法
            // });
            // marker[3].addEventListener("mouseover", function () {
            //   this.openInfoWindow(info[3]); //悬浮监听提示方法
            // });
            // marker[4].addEventListener("mouseover", function () {
            //   this.openInfoWindow(info[4]); //悬浮监听提示方法
            // });
            // marker[5].addEventListener("mouseover", function () {
            //   this.openInfoWindow(info[5]); //悬浮监听提示方法
            // });
            // marker[6].addEventListener("mouseover", function () {
            //   this.openInfoWindow(info[6]); //悬浮监听提示方法
            // });
          }
        })
        .catch((res) => {
          console.log("网络开小差了，没有获取到图片验证码哦");
        });
    },
    doContent(title, imgUrl, description, phone) {
      return (
        "<p style='margin:0 0 5px 0;padding:0.2em 0;>" +
        title +
        "</p>" +
        "<img style='float:right;margin:4px' id='img' src='" +
        imgUrl +
        "' width='139' height='104' title='" +
        title +
        "'/>" +
        "<p style='margin:0;line-height:1.5;font-size:13px;text-indent:2em'>" +
        description +
        "</p>" +
        "<p style='margin:0;line-height:1.5;font-size:13px;text-indent:2em'>" +
        phone +
        "</p>" +
        "</div>"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.lookingdent {
  width: 100%;
  overflow-x: hidden;
  .looking {
    width: 100%;
    height: 1580px;
    position: relative;
    margin-top: 80px;
    .looking_background {
      width: 100%;
      height: 824px;
      background: url("../assets/img/xie/yayi.png");
      background-size: 100% 100%;
      .neibucenter {
        width: 100%;
        height: 100%;
        background: rgba(#1076a8, 0.75);
        position: relative;
      }
    }
    .lookeng_center {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 40px;
      margin: auto;
      max-width: 1250px;
      .lookingname {
        width: 448px;
        height: 145px;
        font-size: 48px;
        color: #ffffff;
        margin: 0 auto;
        margin-top: 170px;
        text-align: center;
      }
      .lookingsher {
        width: 673px;
        height: 48px;
        margin: 0 auto;
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        .left {
          width: 584px;
          height: 48px;
          padding: 16px 30px;
          font-size: 16px;
          color: #7d7d7d;
        }
        .btnsa {
          width: 88px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          background: #1076a8;
          border: 1px solid #075d87;
          color: #fffefe;
          cursor: pointer;
        }
      }
    }
  }
  .mapads {
    width: 100%;
    height: 661px;
    margin-top: 70px;
    border: 10px solid #ffffff;
    z-index: 997;
    position: relative;
    box-shadow: 1px 6px 25px #dbd8d8;
    .lookall {
      position: absolute;
      bottom: 00px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1000;
      width: 228px;
      height: 63px;
      line-height: 63px;
      text-align: center;
      background: #ffffff;
      font-size: 24px;
      color: #1076a8;
      cursor: pointer;
    }
  }
  #allmap {
    width: 100%;
    height: 100%;
    font-size: 24px;
    line-height: 36px;
  }
  .wayname {
    margin-top: 97px;
    text-align: center;
    font-size: 36px;
    color: #1076a8;
  }
  .waytext {
    width: 924px;
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #535353;
  }
  .buttoms {
    width: 92px;
    height: 16px;
    margin: 0 auto;
    margin-top: 50px;
    background: #1076a8;
  }
  .navadlist {
    width: 100%;
    height: 582px;
    background: #f8f8f8;
    .navadlist_center {
      max-width: 1250px;
      height: 100%;
      margin: auto;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      .navadlist_left {
        width: 54%;
        height: 100%;
        .toptitle {
          margin-top: 168px;
          // margin-left: 10px;
          font-size: 32px;
          color: #535353;
        }
        .ul {
          line-height: 35px;
          margin-top: 34px;
          margin-left: 10px;
          padding-left: 20px;
          .li {
            list-style-type: disc;
            font-size: 16px;
            color: #535353;
          }
        }
      }
      .navadlist_right {
        width: 414px;
        margin-top: 100px;
        margin-left: 20px;
      }
    }
  }
  .talluns {
    width: 100%;
    height: 630px;
    text-align: center;
    .tallunsimg {
      width: 644px;
      height: 322px;
      margin-top: 95px;
    }
    .textp {
      width: 572px;
      height: 79px;
      margin: 0 auto;
      margin-top: 50px;
      font-size: 20px;
      color: #535353;
      line-height: 30px;
      text-align: center;
    }
  }
  .displays {
    width: 100%;
    position: relative;
    background-color: rgba(242, 242, 242, 0.39);
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    z-index: 1;
    height: 542px;
    .bottomsbg_left {
      width: 310px;
      text-align: center;
      .title {
        font-size: 40px;
        color: #535353;
      }
      .center {
        margin-top: 32px;
        font-size: 24px;
        color: #535353;
      }
      .btns {
        cursor: pointer;
        width: 200px;
        height: 46px;
        margin: 0 auto;
        margin-top: 47px;
        text-align: center;
        line-height: 46px;
        background: #00a3e0;
        border-radius: 3px;
        font-size: 16px;
        color: #ffffff;
      }
    }
    .bottomimg {
      width: 600px;
      height: 301px;
      margin-left: 163px;
    }
  }
  .foos::before {
    content: "";
    position: absolute;
    z-index: 0;
    height: 542px;
    bottom: 100%;
    left: 52.5%;
    width: 100%;
    background: linear-gradient(hsla(0, 0%, 93%, 0.2), hsla(0, 0%, 85%, 0.2));
    transform: skew(-52deg);
  }
}
</style>