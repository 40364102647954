<template>
  <div>
    <tabBar :cardIndex="2" />
    <!-- <div class="opusmall">
      <div class="titles">Opalescence奥普斯商城</div>
      <div class="imputs">
        <input
          type="text"
          v-model="parms.keyword"
          placeholder="请输入您想查找的商品"
          class="inputs"
        />
        <div class="vielsiut" @click="search">搜索</div>
      </div>
      <triangle
        style="z-index: 99"
        :nums="-40"
        :viewbox="'0 0 1920 215'"
        :pathpoin="'1920 289.4 0 289.4 0 22 960 172.1 1920 22'"
        :compoin="'1920 0 1920 22 960 173.1 0 22 0 0 960 150.1'"
      ></triangle>
    </div> -->
		<div class="bannertop">
			<div class="opusmall">
			  <div class="titles">Opalescence奥普斯商城</div>
			  <div class="imputs">
			    <input
			      type="text"
			      v-model="parms.keyword"
			      placeholder="请输入您想查找的商品"
			      class="inputs" @keyup.enter='search'
			    />
			    <div class="vielsiut" @click="search">搜索</div>
			  </div>
			</div>
		  <el-carousel :interval="3000" height="26.25rem" :autoplay="true" 
			:indicator-position="'none'" ref="carousel">
		    <el-carousel-item v-for="(item, index) in bannerList" :key="index">
		      <div class="banner_div">
		        <img class="bgimgs" :src="item.img" alt="" @click="topagestype(item)" />
		      </div>
		    </el-carousel-item>
		  </el-carousel>
		  <triangle
		    style="z-index: 99"
		    :nums="-28"
		    :viewbox="'0 0 1920 215'"
		    :pathpoin="'1920 289.4 0 289.4 0 22 960 172.1 1920 22'"
		    :compoin="'1920 0 1920 22 960 173.1 0 22 0 0 960 150.1'"
		  ></triangle>
		</div>
    <div class="bgcenters">
      <div class="bgcenters_cen">
        <div class="lsicents">
          <div class="left" @click="toactivepage(2)">
            <img class="leftimg" src="../assets/img/jifenaaa.png" alt="" />
            <div class="left_text">
              <div class="titles">积分兑换</div>
              <div class="texts">
                皓齿居家美白Opalescence
                PF牙齿美白凝胶是专业的牙齿美白凝胶，只在专业的牙医处销售。
              </div>
              <div class="togous">查询积分---></div>
            </div>
          </div>
          <div class="right" @click="toactivepage(1)">
            <img class="leftimg" src="../assets/img/yingixao.png" alt="" />
            <div class="left_text">
              <div class="titles">营销专区</div>
              <div class="texts">
                Opalescence™
                牙齿美白系列的研究比地球上任何品牌都多。您的牙医可以推荐安全可靠的美白体验。
              </div>
              <div class="togous">了解专区---></div>
            </div>
          </div>
        </div>
        <div class="texttitle">精选商品</div>
        <div class="lsitadposcen">
          <div
            class="goodeslist"
            v-for="(item, index) in goodsList"
            :key="index"
            @mouseenter="enter(index)"
            @mouseleave="leave(index)"
          >
            <img class="goodesimg" :src="item.content.thumbnail"/>
            <div class="mengbang" v-if="active != index"></div>
            <div class="goodsbotom">
              <div class="titles">{{ item.content.goodsName }}</div>
              <div class="price">
                <span class="prices"
                  ><span class="rmbs">￥</span>{{ item.content.price }}</span
                >
                <div
                  class="price_btns"
                  v-if="active == index"
                  @click="gotos(item.content.id)"
                >
                  立即购买
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import tabBar from "@/components/tabBar.vue";
import triangle from "@/components/triangle.vue";
import footers from "@/components/footer.vue";
import { getGoodsEs } from "../request/homeApi";
export default {
  data() {
    return {
      isAchiveBottom: false, //滚动条是否到底部标志
      active: null,
      noMore:null,
      parms: {
        pageNumber: 1,
        pageSize: 12,
      },
      goodsList: [],
			bannerList:[],
			total:'',
    };
  },
  components: {
    tabBar,
    triangle,
    footers,
  },
  created() {
    //使用window.onscroll = function(){}this指向出现问题
    //故应该使用箭头函数，因为箭头函数无this，会从上一级找，故会找到vue实例的this
    window.onscroll = () => {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件(距底部20px时触发加载)
      if (
        scrollTop + windowHeight >= scrollHeight - 450 &&
        !this.isAchiveBottom) {
        this.isAchiveBottom = true;
        //延时触发数据加载
        setTimeout(() => {
          //后端需要进行分页，然后前端从后端拿来实现滚动加载
          //这里利用数组push来模拟从后端拿到的数据
          // this.datas.push("test");
					if(this.parms.pageNumber<=this.total){
						this.parms.pageNumber += 1;
						this.getlistads();
					}
        }, 500);
      }
    };
  },
  beforeMount() {
    // 在页面挂载前就发起请求
    //this.parms.pageNumber += 1;
    //this.getlistads();
  },
  mounted() {
    this.getlistads();
  },
  methods: {
    getlistads(index) {
      getGoodsEs(this.parms).then((res) => {
          if (res.code == 200) {
            // this.goodsList = res.result.goodsList.content;
            if (index == 2) {
              this.$nextTick(() => {
                 window.scrollTo(0, 600)
              });
            }
						this.bannerList=res.result.bannerList;
            res.result.goodsList.content.forEach((element) => {
              this.goodsList.push(element);
            });
            this.isAchiveBottom = false;
						this.total=res.result.goodsList.totalPages
          }
        })
    },
    //搜索
    search() {
      this.goodsList = [];
      this.parms.pageNumber = 1
      this.getlistads(2);
    },
		//点击轮播图
		topagestype(item) {
		  if (item.linkType == 0) {
		    this.$router.push({ path: "/activityORintegral?active=2" });
		  }
		  if (item.linkType == 1) {
		    this.$router.push({ path: "/activityORintegral?active=1" });
		  }
		  if (item.linkType == 2) {
		    this.$router.push({ path: "/shopdet?id=" + item.goodsId });
		  }
		
		  console.log(item);
		},
    toactivepage(index) {
      this.$router.push({ path: "activityORintegral?active=" + index });
    },
    enter(index) {
      this.active = index;
    },
    leave(index) {
      this.active = null;
    },
    gotos(id) {
      this.$router.push({
        path: "./shopdet?id=" + id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
	::v-deep .bannertop {
		margin-top: 80px;
	  width: 100%;
	  height: 440px;
	  position: relative;
	  .banner_div {
	    cursor: pointer;
	    height: 100%;
	    position: relative;
	    .bgimgs {
	      width: 100%;
				height: 100%;
	    }
	  }
		.el-carousel__arrow{
			top: 36%;
		}
		.opusmall {
			position: absolute;
			left:50%;
			top: 0;
			transform: translateX(-50%);
			z-index: 9;
		  .titles {
		    padding-top: 90px;
		    text-align: center;
		    font-size: 40px;
		    color: #ffffff;
		  }
		  .imputs {
		    width: 766px;
		    height: 68px;
		    // background: #ffffff;
		    border-radius: 1px;
		    display: flex;
		    align-items: center;
		    margin: 0 auto;
		    margin-top: 36px;
		    cursor: pointer;
		    .inputs {
		      width: 560px;
		      height: 68px;
		      padding: 0px 28px;
		      font-size: 16px;
		      color: #333333;
		    }
		    .vielsiut {
		      width: 205px;
		      height: 68px;
		      text-align: center;
		      line-height: 67px;
		      background: #0075a9;
		      border-radius: 1px;
		      font-size: 24px;
		      color: #f8f8f8;
		    }
		  }
		}
}
.bgcenters {
  width: 100%;
  height: 100%;
  background: url("../assets/img/216.png") no-repeat;
  background-position: -33% -30%;
  .bgcenters_cen {
    width: 100%;
    height: 100%;
    background: url("../assets/img/227.png") no-repeat;
    background-position: 140% 100%;
    padding-bottom: 137px;
    .lsicents {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 64px;
      .left,
      .right {
        width: 602px;
        height: 244px;
        display: flex;
        justify-content: start;
        cursor: pointer;
        .leftimg {
          margin-left: 38px;
          margin-top: 38px;
          width: 86px;
          height: 86px;
          border-radius: 50%;
        }
        .left_text {
          width: 70%;
          margin-left: 23px;
          font-size: 16px;
          color: #ffffff;
          .titles {
            margin-top: 40px;
            font-size: 36px;
          }
          .texts {
            width: 100%;
            margin: 25px 0 24px;
          }
          // .togous {
          //   cursor: pointer;
          // }
        }
      }
      .left {
        background: linear-gradient(161deg, #4ad1f6, #0f9ce9);
        margin-right: 47px;
      }
      .right {
        background: linear-gradient(135deg, #f5c152, #e49d13);
      }
    }
  }
}
.texttitle {
  margin-top: 74px;
  text-align: center;
  font-size: 40px;
  color: #333333;
}
.lsitadposcen {
  max-width: 1250px;
  margin: 0 auto;
  margin-top: 76px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .goodeslist {
    position: relative;
    width: 392px;
    margin-bottom: 37px;
    box-shadow: 0px 8px 39px 7px rgba(55, 55, 55, 0.04);
    cursor: pointer;
    .mengbang {
      width: 392px;
      height: 222px;
      // background: rgba(0, 0, 0, 0.5);
      z-index: 99;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
    }
    // float: left;
    .goodesimg {
      width: 392px;
      height: 221px;
			background-color: #fff;
    }
    .goodsbotom {
			background-color: #fff;
      padding: 15px 20px;
      .titles {
        width: 100%;
        word-break: break-all;
					text-overflow: ellipsis;
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
        font-size: 24px;
        color: #333333;
      }
      .price {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .prices {
          color: #00a3e0;
          font-size: 31px;
          .rmbs {
            font-size: 20px;
          }
        }
        .price_btns {
          width: 134px;
          height: 40px;
          background: #0075aa;
          color: #ffffff;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          line-height: 40px;
        }
      }
    }
  }
}
.lsitadposcen::after{
	content: '';
	width: 392px;
}
</style>