<template>
  <div class="about">
    <tabBar :cardIndex="4"></tabBar>
    <div class="homes">
      <video
        class="vadeols"
        controls
        src="https://opalescence.oss-cn-beijing.aliyuncs.com/itshow/We%20Are%20Ultradent%20Products%2C%20Inc-_1.mp4"
      ></video>
    </div>
    <div class="contens">
      <div class="topcont">
        <img class="con_img" src="../assets/img/xie/ul.png" />
        <div style="font-size: 36px">超过40年的专业牙齿美白</div>
        <div style="margin: 40px 0 0 0">
          我们是一家致力于通过科学、创造力和教育改善全球口腔健康的公司。改善口腔健康的热情促使皓齿产品畅销全球，
          同时成就了连续增长40年牙科制造公司。
        </div>
        <div>
          我们有垂直整合的学科，包括化学、工程、自动化、机器人、营销等。因为我们所有的工作都是在公司内部完成的，
          所以无论你的专长是什么，我们都为你提供了一席之地。
        </div>
        <div class="con_but" @click="routes(1)">进一步了解我们</div>
      </div>
    </div>
		<div class="shipin">
			<video
			  controls
			  src="https://opalescence.oss-cn-beijing.aliyuncs.com/video/World%20Leader%20in%20Whitening_Chinese_VO-1920.mp4?versionId=CAEQGRiBgICN1NfL.hciIDU2NDY0NWY3ZWVmNzQ4NWNhZjA0ODlhNzhjZDQ2MzBj"
			></video>
		</div>
    <div class="learn">
      <img class="ler_img" src="../assets/img/opal1.png" />
      <div style="font-size: 36px; margin-top: 48px; margin-bottom: 40px">
        奥普斯牙齿美白之旅
      </div>
      <div class="ler_tex">
        当时Fischer博士14岁的女儿Jaleena想让牙齿变得更白一些，于是Fischer博士为了实现女儿的愿望，全身心投入美
        白产品的研发。最终，在1991年的一个晚上，Fischer博士拿着研发完成的奥普斯牙齿美白凝胶
        给女儿佩戴仅用了一晚上时间，牙齿色阶就从A3提升到A1.
      </div>
      <div class="ler_but" @click="routes(2)">了解更多牙齿美白</div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import tabBar from "@/components/tabBar.vue";
import triangle from "@/components/triangle.vue";
import footers from "@/components/footer.vue";
export default {
  components: {
    tabBar,
    triangle,
    footers,
  },
  data() {
    return {
      videoOptions: {
        controls: true,
      },
      dialogVisible: false,
    };
  },
  mounted() {
    //this.initVideo();
  },
  methods: {
	  seeking(){

	  },
    videtips() {
      this.dialogVisible = true;
    },
    removemodal() {
      this.dialogVisible = false;
	   this.player.dispose();
    },
    initVideo() {
      //原生初始化视频方法
      let myVideo = this.$refs.video;
      //ontimeupdate
      myVideo.ontimeupdate = function () {
        myFunction();
      };
      let _this = this;

      function myFunction() {
        let playTime = myVideo.currentTime;
        setTimeout(function () {
          localStorage.setItem("cacheTime", playTime);
        }, 500);
        let time = localStorage.getItem("cacheTime");
        // 当前播放位置发生变化时触发。
        if (playTime - Number(time) > 2) {
          myVideo.currentTime = Number(time);
        } else {
        }
      }
    },
    // 播放回调
    onPlayerPlay(player) {
      // this.globalSetting = true
      console.log("player play!", player);
      // document.getElementsByClassName("vjs-control-bar").style.display = "block";
      // document.getElementsByClassName("vjs-control-bar").style.display = "block";
    },

    // 暂停回调
    onPlayerPause(player) {
      // this.globalSetting.controls = false;
      console.log("player pause!", player);
      // var video = document.getElementById("video");
      // video.controls=false;
      // document.getElementsByClassName("vjs-control-bar").style.display = "none";
    },
		//跳转页面
		routes(ind){
			if(ind==1){
        window.open('https://www.ultradent.cn/', '_blank');
        // this.$router.push({
				// 	path:'/principle'
				// })
			}else{
				this.$router.push({
					path:'/principle'
				})
			}
		}
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style scoped lang="scss">
.about {
  width: 100%;
  overflow-x: hidden;
  .homes {
		margin-top: 80px;
    width: 100%;
    height: 600px;
    position: relative;
    background-color: #00193a;
    .vadeols {
      width: 100%;
      height: 100%;
    }
    .playimg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      z-index: 888;
    }
  }
  .contens {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    // background-image: url("../assets/img/xie/556.png");
    // background-size: 100% 100%;
    .topcont {
      width: 825px;
      display: flex;
      flex-flow: column;
      align-items: center;
      color: #535353;
      font-size: 16px;
      //margin-bottom: 44px;
      text-align: center;
      .con_img {
        width: 220px;
        height: 144px;
        margin-bottom: 48px;
      }
      .con_but {
        cursor: pointer;
        width: 207px;
        height: 46px;
        background-color: #ea5a14;
        border-radius: 3px;
        color: #fff;
        line-height: 46px;
        text-align: center;
        margin-top: 40px;
      }
    }
    .shop {
      display: flex;
      align-items: center;
      .shop_img {
        width: 570px;
        height: 243px;
        display: block;
        margin-bottom: 14px;
      }
      .shop_but {
        margin-left: 50px;
        width: 190px;
        height: 46px;
        border-radius: 3px;
        background-color: #00a3e0;
        color: #fff;
        font-size: 16px;
        line-height: 46px;
        text-align: center;
        cursor: pointer;
      }
    }
    .shop_boot {
      width: 100%;
      height: 60px;
      background-color: #c2c6cc;
      line-height: 60px;
      text-align: center;
      color: #fff;
      font-size: 24px;
    }
  }
	.shipin{
		width: 100%;
		height: 600px;
		margin-top: 110px;
		video{
			width: 100%;
			height: 600px;
		}
	}
  .learn {
    width: 100%;
    height: 530px;
    display: flex;
    flex-flow: column;
    align-items: center;
    //justify-content: center;
    color: #535353;
    font-size: 16px;
    background-image: url("../assets/img/xie/tixing.png");
    background-size: 100% 100%;
    .ler_img {
      display: block;
      width: 424px;
			margin-top: 60px;
    }
    .ler_tex {
      max-width: 820px;
      margin-bottom: 40px;
      text-align: center;
    }
    .ler_but {
      cursor: pointer;
      width: 210px;
      height: 46px;
      background-color: #00a3e0;
      border-radius: 3px;
      text-align: center;
      line-height: 46px;
      color: #fff;
    }
  }
}

.moadals {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  .modalscenter {
    width: 50%;
    height: 580px;
    margin: 0 auto;
    margin-top: 10%;
    .vadeols {
      width: 100%;
      height: 100%;
    }
    .removeguanboi {
      position: absolute;
      top: 17%;
      right: 20%;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      overflow: hidden;
      text-align: center;
      line-height: 20px;
      background: #ffffff;
      color: #000000;
      font-size: 12px;
	  cursor: pointer;
    }
  }
}
</style>