<template>
  <div class="compare">
    <tabBar :cardIndex="1" :activeIndexsa="0" />
    <!-- <div style="height: 85px">
      <div class="headers">
				<div class="headss">
					<div
					  v-for="(item, index) in list"
					  :key="index"
					  class="tips"
					  :class="filg == index ? 'active_tip' : ''"
					  @click="qies(index)"
					>
					  <img v-if="filg == index" :src="item.imgs" />
					  <img v-else :src="item.img" />
					  <span>{{ item.name }}</span>
					</div>
				</div>
      </div>
    </div> -->
    <div class="comparecenter">
      <div class="compare_centers">
        <div class="compare_left">
          <ul class="list_ul">
            <li
              v-for="(items, index) in datas"
              class="list-item"
              :key="index"
              @click="todetails(items.id)"
            >
              <div class="litop">
                <img
                  class="heder_img"
                  src="../assets/img/touxing101.png"
                  alt=""
                />
                <span class="topsapn">美国皓齿</span>
              </div>
              <div class="licenter">
                <img class="licentimg" :src="items.img" />
                <div class="licext_right">
                  <div class="righttop">{{ items.title }}</div>
                  <div class="rightbottom" v-html="items.content"></div>
                </div>
              </div>
              <div class="libottom">
                <div class="btncard">
                  <img
                    class="imgs"
                    src="../assets/img/biaoqian5627.png"
                    alt=""
                  />
                  {{ items.labelName }}
                </div>
                <div class="caopzuo">
                  <div class="allcss liulan">{{ items.viewNum }}浏览</div>
                  <div
                    class="allcss pinglun"
                    @click.stop="pinglunclick(items.id, index)"
                  >
                    <img
                      class="imgs"
                      src="../assets/img/xie/pinglun.png"
                      alt=""
                    />{{ items.replyNum }}
                  </div>
                  <div
                    class="allcss dainzan"
                    @click.stop="dianzans(items.id, index, !items.isDoUp)"
                  >
                    <img
                      class="imgs"
                      v-if="items.isDoUp"
                      src="../assets/img/4509.png"
                      alt=""
                    />
                    <img
                      class="imgs"
                      v-if="!items.isDoUp"
                      src="../assets/img/xie/zan.png"
                      alt=""
                    /><span :style="items.isDoUp ? 'color:#f26034' : ''">{{
                      items.giveNum
                    }}</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="noalls" v-if="istotals">没有更多了</div>
        </div>
        <div class="rightlsit">
          <div
            class="compare_right"
            ref="compareright"
            :style="'height: ' + height + 'px'"
            v-if="tuiList.length > 0"
          >
            <div class="titiles" ref="title">为你推荐</div>
            <div class="rights" ref="rightsres">
              <div
                class="rigtlist"
                v-for="(item, index) in tuiList"
                :key="index"
                :style="tuiList.length - 1 == index ? 'border:none' : ''"
                @click="todetails(item.id)"
              >
                <img class="imgsa" :src="item.img" alt="" />
                <div class="texttitle">
                  {{ item.title }}
                </div>
                <div class="texts" v-html="item.content"></div>
              </div>
            </div>
          </div>
          <div class="biaoqians">
            <div class="titles">标签选择</div>
            <div class="biaolsit">
              <div class="lisad" :style="activesidx==100?'color:#00a3e0;':''"  @click="topages('',100)">全部</div>
               <div class="lisad" :style="activesidx==index?'color:#00a3e0;':''" v-for="(item,index) in labList" :key="index" @click="topages(item.name,index)">#{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="评论"
      :visible.sync="dialogVisible"
      :show-close="false"
      width="30%"
    >
      <div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入内容"
          v-model="content"
        >
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="removedialogVisible">取 消</el-button>
        <el-button type="primary" @click="dialogVisibleOkes">确 定</el-button>
      </div>
    </el-dialog>
    <footers></footers>
  </div>
</template>

<script>
// @ is an alias to /src
import tabBar from "@/components/tabBar.vue";
import footers from "@/components/footer.vue";
import { getforumLsit, putaddUp, postforumReply } from "../request/homeApi";

export default {
  name: "Home",
  components: {
    tabBar,
    footers,
  },
  data() {
    return {
      dialogVisible: false,
      istotals: false,
      filg: 0,
      datas: [],
      params: {
        labelName:"",
        pageNumber: 1,
        pageSize: 10,
        type: 0,
      },
      activesidx:100,
      labList:[
        {
          name:'美白牙膏',
        },{
          name:'抗敏牙膏',
        },{
          name:'美白托盘',
        },{
          name:'随时白',
        },{
          name:'皓齿美白',
        },{
          name:'美国皓齿',
        },{
          name:'牙齿美白秘籍',
        },{
          name:'美白小妙招',
        },{
          name:'皓齿百科',
        },{
          name:'皓齿之声',
        },{
          name:'美白大师',
        },{
          name:'案例分享',
        },{
          name:'教程',
        },{
          name:'奥普斯',
        },{
          name:'牙齿保护',
        },{
          name:'口腔预防',
        },
      ],
      tuiList: [],
      count: 5, //默认加载数量
      isAchiveBottom: false, //滚动条是否到底部标志
      height: 0,
      list: [
        {
          name: "博客",
          img: require("../assets/img/xie/boke.png"),
          imgs: require("../assets/img/xie/boke2.png"),
        },
        {
          name: "论坛",
          img: require("../assets/img/xie/luntan2.png"),
          imgs: require("../assets/img/xie/luntan.png"),
        },
      ],
      content: "",
      textid: "",
      textindex: null,
      tokens: "",
    };
  },
  created() {
    //使用window.onscroll = function(){}this指向出现问题
    //故应该使用箭头函数，因为箭头函数无this，会从上一级找，故会找到vue实例的this
    window.onscroll = () => {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件(距底部20px时触发加载)
      if (
        scrollTop + windowHeight >= scrollHeight - 450 &&
        !this.isAchiveBottom &&
        !this.noMore
      ) {
        this.isAchiveBottom = true;
        //延时触发数据加载
        setTimeout(() => {
          //后端需要进行分页，然后前端从后端拿来实现滚动加载
          //这里利用数组push来模拟从后端拿到的数据
          // this.datas.push("test");
          this.params.pageNumber += 1;
          this.lsitad();
        }, 500);
      }
    };
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.tokens = localStorage.getItem("token");
    }
    // this.height = this.$refs.title.clientHeight + this.$refs.title.rights;
    setTimeout(() => {
      this.height =
        this.$refs.title.clientHeight + this.$refs.rightsres.clientHeight + 50;
    }, 200);
  },
  beforeMount() {
    // 在页面挂载前就发起请求
    this.lsitad();
  },
  methods: {
    topages(name,index){
      this.activesidx = index
       this.params.labelName = name;
       this.params.pageNumber = 1;
       this.datas = [];
          this.lsitad();
           window.scrollTo(0, 0)
    },
    dialogVisibleOkes() {
      let data = {
        forumId: this.textid,
        content: this.content,
      };

      postforumReply(data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.content = "";
            this.textid = "";
            this.dialogVisible = false;
            this.datas[textindex].replyNum =
              Number(this.datas[textindex].replyNum) + 1;
            this.$forceUpdate();
            this.$message.success("评论成功！");
          }
        })
        .catch((res) => {
          console.log("网络开小差了，没有获取到图片验证码哦");
        });
    },
    removedialogVisible() {
      this.dialogVisible = false;
      this.content = "";
      this.textid = "";
    },
    // 评论
    pinglunclick(id, index) {
      if (!this.tokens) {
        this.$message.error("请登录之后再操作");
        return false;
      }
      this.dialogVisible = true;
      this.content = "";
      this.textindex = index;
      this.textid = id;
    },
    // 点赞
    dianzans(id, index, isdianzan) {
      if (!this.tokens) {
        this.$message.error("请登录之后再操作");
        return false;
      }
      putaddUp(id, isdianzan)
        .then((res) => {
          if (res.code == 200) {
            if (isdianzan) {
              this.datas[index].isDoUp = true;
              this.datas[index].giveNum = Number(this.datas[index].giveNum) + 1;
            } else {
              this.datas[index].isDoUp = false;
              this.datas[index].giveNum = Number(this.datas[index].giveNum) - 1;
            }

            this.$message.success("操作成功！");
          }
        })
        .catch((res) => {
          console.log("网络开小差了，没有获取到图片验证码哦");
        });
    },
    todetails(id) {
      this.$router.push({ path: "blogsdetail?id=" + id });
    },
    //切换
    qies(ind, ids) {
      if (ind == 1) {
        this.$router.push({ path: "forumlist" });
      }
      this.$nextTick(() => {
        this.filg = ind;
      });
    },

    lsitad() {
      getforumLsit(this.params)
        .then((res) => {
          if (res.code == 200) {
            if (this.datas.length == res.result.list.total) {
              this.istotals = true;
              return;
            }
            res.result.list.records.forEach((item) => {
              if (item.content) {
                let sk = item.content.replace(/<img.*>.*<\/img>/gi, "");
                sk = sk.replace(/<img.*\/>/gi, "");
                item.content = sk;
              }
              this.datas.push(item);
            });
            res.result.isSelectd.forEach((item) => {
              if (item.content) {
                let skr = item.content.replace(/<img.*>.*<\/img>/gi, "");
                skr = skr.replace(/<img.*\/>/gi, "");
                item.content = skr;
              }
            });
            this.tuiList = res.result.isSelectd;
            this.isAchiveBottom = false;
          }
        })
        .catch((res) => {
          console.log("网络开小差了，没有获取到图片验证码哦");
        });
    },
  },
};
</script>
<style scoped lang="scss">
.headers {
  width: 100%;
  height: 85px;
  background-color: #ededed;
  color: #535353;
  font-size: 16px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 80px;
  .headss {
    margin-left: 80px;
    height: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }
  .tips {
    margin-left: 95px;
    cursor: pointer;
    display: flex;
    flex-flow: column;
    justify-content: center;
    img {
      width: 33px;
      height: 31px;
    }
  }
  .active_tip {
    color: #00a3e0;
    // border-bottom: 4px solid #0075AA;
  }
}
.compare {
  background-color: #f7f7f7;
}
.comparecenter {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  padding-top: 100px;
  .compare_centers {
    width: 1250px;
    height: 100%;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    .compare_left {
      width: 670px;
      background: #ffffff;
      .list_ul {
        width: 100%;
        padding: 0 22px;
        overflow: hidden;
        .list-item {
          width: 100%;
          border-bottom: 1px solid #e1e1e1;
          cursor: pointer;
          .litop {
            display: flex;
            align-items: center;
            margin-top: 26px;
            .heder_img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-right: 12px;
            }
            .topsapn {
              font-size: 16px;
              color: #535353;
            }
          }
          .licenter {
            display: flex;
            align-items: center;
            margin-top: 27px;
            .licentimg {
              width: 198px;
              height: 110px;
              margin-right: 12px;
              background-size: contain;
              background-position: center;
            }
            .licext_right {
              width: 65%;
              height: 110px;

              .righttop {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                font-size: 18px;
                color: #535353;
              }
              ::v-deep .rightbottom {
                margin-top: 13px;
                width: 100%;
                height: 70px;
                font-size: 16px;
                color: #999999;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                p {
                  margin: 0;
                }
              }
            }
          }
          .libottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .btncard {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 4px 16px;
              cursor: pointer;
              // line-height: 30px;
              background: #f0f9ff;
              border-radius: 15px;
              color: #00a3e0;
              .imgs {
                width: 12px;
                height: 12px;
                margin-right: 4px;
              }
            }
            .caopzuo {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin: 30px 0 25px;
              .allcss {
                display: flex;
                align-items: center;
                font-size: 16px;
                color: #999999;
                cursor: pointer;
                .imgs {
                  width: 19px;
                  height: 18px;
                  margin-right: 6px;
                }
              }
              .pinglun {
                margin: 0 30px;
              }
            }
          }
        }
      }
    }
    .rightlsit {
      width: 323px;
       margin-left: 12px;
      .compare_right {
        width: 323px;
        display: inline-block;
        padding: 20px 18px;
       
        background: #ffffff;
        .titiles {
          font-size: 18px;
          color: #535353;
        }
        .rights {
          .rigtlist {
            margin-top: 25px;
            width: 100%;
            padding-bottom: 20px;
            border-bottom: 1px solid #e1e1e1;
            .imgsa {
              width: 100%;
              height: 165px;
            }
            .texttitle {
              width: 100%;
              font-size: 18px;
              color: #535353;
              margin-top: 15px;
            }
            .texts {
              margin-top: 12px;
              width: 100%;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              font-size: 16px;
              color: #999999;
              ::v-deep p {
                margin: 0;
              }
            }
          }
          .rigtlist:last-child {
            border: none;
          }
        }
      }
      .biaoqians{
        margin-top: 10px;
        display: inline-block;
        background: #ffffff;
         padding: 20px 18px;
        .titles{
          font-size: 18px;
          color: #535353;
          margin-bottom: 10px;
        }
        .biaolsit{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .lisad{
            cursor: pointer;
            margin-right: 10px;
            margin-bottom: 10px;
            color: #999999;
            border: 1px solid #e1e1e1;
            display: inline-block;
            padding: 6px 20px;
          }
        }
      }
    }
  }
}
.noalls {
  text-align: center;
  padding: 10px 0 20px;
  font-size: 16px;
  color: #999999;
}
</style>