<template>
  <div class="princ">
    <tabBar :cardIndex="0" :activeIndexs="2" />
    <div class="homes">
      <img src="../../assets/img/xie/meibai.png" />
    </div>
    <div class="texcen">值得信赖的牙齿美白</div>
    <div class="contents">
      <div class="textcont">
        <div style="margin-right: 105px">
          <div class="texttip">灿烂笑容的开始</div>
          <div>
            Jaleena坚持说：“爸爸，我需要更白的牙齿。爸爸，我需要让我的牙齿更白。”她甚至会从牙科杂志上剪掉牙齿美白广告，并在她父亲的床头柜上留下手写的提醒。作为Fischer博士十几岁的女儿，Jaleena决心让她父亲的注意力集中在开发后来成为Opalescence Teeth Whitening的产品上。
          </div>
          <div style="margin-top: 30px">
            30 多年前的一个晚上，当 Fischer博士终于对他和他的团队开发的牙齿美白凝胶感到满意时，他把它带回了Jaleena。他让她在睡觉前将新凝胶装入牙齿美白托盘，并要求她整晚都佩戴它。“当你早上醒来时，不要把托盘拿出来，”他说。“来找我，我们一起拿出来。”第二天早上，他们拿出托盘，发现只用了一晚后，用 Opalescence牙齿美白凝胶处理过的牙齿白了两个色度！
          </div>
          <div class="texttip">Opalescence 牙齿美白的工作原理</div>
          <div>
            秘密在于 Opalescence 牙齿美白凝胶的粘度。Fischer博士知道凝胶需要有一种粘稠的组合物才能保持原位并提供持续的过氧化物释放，从而获得出色的美白效果。Opalescence牙齿美白凝胶独特的粘度使凝胶保持在原位，让过氧化物进入您的牙釉质并发挥作用。
          </div>
        </div>
        <div>
          <img class="imgs" src="../../assets/img/xie/jies.png" />
          <div>
            当来自美白剂的氧分子扩散到整个牙齿并与牙齿中变色的分子发生反应时，就会发生牙齿美白。这意味着整个牙齿都被美白了，即使美白凝胶没有与牙釉质的每个部分接触。这种安全、无创且有效的美白方法使Opalescence牙齿美白成为专业牙齿美白领域的全球领导者，并受到牙医和患者的信赖。
          </div>
          <div style="margin-top: 34px">
            Jaleena 和她父亲的那个多事的早晨已经过去了 30年。在那段时间，Opalescence牙齿美白已经帮助数百万人焕发了笑容，让他们更加自信并提高了生活质量。它可以为您做同样的事情！探索您的美白可能性，并立即与您的牙医讨论哪种Opalescence 牙齿美白产品适合您。
          </div>
        </div>
      </div>
      <div class="shop">
        <div style="margin-right: 93px">
          <div class="shop_img"></div>
          <div class="shop_but" @click="routs">探索牙齿美白</div>
        </div>
        <div style="width: 412px; height: 663px"></div>
      </div>
      <div class="shop_boot">有关更多信息，请咨询牙科专家</div>
    </div>
    <el-row class="prinfoot">
      <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
      <el-col :span="16">
        <div
          class="prinss"
          style="
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: flex-end;
          "
        >
          <img class="foot_img" src="../../assets/img/Dentist.png" />
          <div class="foot_right">
            <img class="foot_rigimg" src="../../assets/img/xie/dizhi.png" />
            <div style="font-size: 36px; margin-top: 25px; font-weight: bold">
              从今天开始美白
            </div>
            <div style="text-align: center; font-size: 24px; margin-top: 46px">
              搜索寻找附近的皓齿美白大师，<br />量身定制最适合您的牙齿美白方案！
            </div>
            <div class="foot_but" @click="routes">寻找您附近的美白牙医</div>
          </div>
        </div>
      </el-col>
      <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
    </el-row>
    <footers class="foos"></footers>
  </div>
</template>

<script>
import tabBar from "@/components/tabBar.vue";
import triangle from "@/components/triangle.vue";
import footers from "@/components/footer.vue";
export default {
  components: {
    tabBar,
    triangle,
    footers,
  },
  data() {
    return {};
  },
  methods: {
    //跳转寻找牙医
    routes() {
      this.$router.push("./lookingdentist");
    },
    //跳转美白托盘
    routs() {
      this.$router.push("./skinwhitening");
    },
  },
};
</script>

<style scoped lang="scss">
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.princ {
  width: 100%;
  overflow-x: hidden;
  .homes {
    margin-top: 80px;
    img {
      width: 100%;
      height: 690px;
    }
  }
  .texcen {
    width: 100%;
    text-align: center;
    color: #535353;
    font-size: 36px;
    margin-top: 86px;
    margin-bottom: 20px;
  }
  .contents {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    background-image: url("../../assets/img/xie/556.png");
    background-size: 100% 100%;
    .textcont {
      color: #535353;
      display: flex;
      div {
        width: 455px;
      }
      .texttip {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 17px;
        margin-top: 61px;
      }
      .imgs {
        margin-top: 60px;
        width: 100%;
        height: 290px;
        display: block;
        margin-bottom: 23px;
      }
    }
    .shop {
      display: flex;
      align-items: center;
      .shop_img {
        width: 570px;
        height: 243px;
        display: block;
        margin-bottom: 20px;
      }
      .shop_but {
        margin-left: 50px;
        width: 190px;
        height: 46px;
        border-radius: 3px;
        background-color: #00a3e0;
        color: #fff;
        font-size: 16px;
        line-height: 46px;
        text-align: center;
        cursor: pointer;
      }
    }
    .shop_boot {
      width: 100%;
      height: 60px;
      background-color: #c2c6cc;
      line-height: 60px;
      text-align: center;
      color: #fff;
      font-size: 24px;
    }
  }
  .prinfoot {
    height: 693px;
    width: 100%;
    color: #535353;
    position: relative;
    z-index: 1;
    .foot_img {
      width: 519px;
      height: 619px;
      display: block;
      margin-top: 74px;
    }
    .gos_img {
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
    }
    .foot_right {
      width: 513px;
      //height: 619px;
      //margin-top: 74px;
      display: flex;
      flex-flow: column;
      align-items: center;
      .foot_rigimg {
        width: 77px;
        height: 96px;
        //margin-top: 170px;
      }
      .foot_but {
        width: 245px;
        height: 48px;
        border-radius: 2px;
        background-color: #00a3e0;
        color: #fff;
        font-size: 16px;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        margin-bottom: 100px;
        margin-top: 60px;
      }
    }
  }
  .foos::before {
    content: "";
    position: absolute;
    z-index: 0;
    height: 693px;
    bottom: 100%;
    left: 38.8%;
    width: 100%;
    background: linear-gradient(hsla(0, 0%, 93%, 0.2), hsla(0, 0%, 85%, 0.2));
    transform: skew(-52deg);
  }
}
</style>
